import MiniCalendar from "components/calendar/MiniCalendar";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";

import { IoMdHome, IoMdDocument, IoMdSchool  } from "react-icons/io";
// import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDocumentScanner } from "react-icons/md";
import { FaSchool, FaUsers, FaRobot } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

// import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import TaskCard from "views/admin/default/components/TaskCard";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataComplex from "./variables/tableDataComplex.json";
import useStats from "hooks/useStats";
import { useEffect, useState } from "react";
import Loader from "components/loader";
import usePublications from "hooks/usePublications";
// import { now } from "lodash";
import useUsers from "hooks/useUsers";
import ProvincesTable from "./components/ProvincesTable";


const Dashboard = () => {
  const { isLoading, fetchData } = useStats('main')
  const [stats, setStats] = useState()
  const { isLoading: isLoadingPublications, response, fetchData: fetchPublications, getPostulateds } = usePublications()
  const [totalPostulaciones, setTotalPostulaciones] = useState(0)
  const [totalNoEspontaneas, setTotalNoEspontaneas] = useState(0)
  const [totalEspontaneas, setTotalEspontaneas] = useState(0)
  const [totalInstCPubActivas, setTotalInstCPubActivas] = useState(0)
  const [totalPostulantes, seTotalPostulantes] = useState(0)
  const [totalPostPublicos, seTotalPostPublicos] = useState(0)
  const [totalUsers, setTotalUsers] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [cvsConIA, setCvsConIA] = useState(0)
  const [cvsIncompletos, setCvsIncompletos] = useState(0)

  const { isLoading: isLoadingUsers, response: users, fetchData: getUsers } = useUsers();


  const fetchStats = async () => {
    const data = await fetchData()
    setStats(data)
  }

  const fetchGetPublications = async () => {
    const data = await fetchPublications(null, {}, true)
    const fechaActual = new Date().toISOString().split('T')[0];

    // console.log(data.data.filter(pub => pub.cuenta_id == 2))
    let totalpubActivas = data?.data?.filter(pub => pub?.activa === 1 && pub?.aprobada === 1 && pub?.finalizada === 0 && pub?.pausada === 0 && pub?.vencimiento?.split(' ')[0] > fechaActual)
    // console.log(totalpubActivas)


    const publicacionesPorCuenta = totalpubActivas?.reduce((acumulador, pub) => {
      const id_cuenta = pub?.cuenta_id;
      acumulador[id_cuenta] = (acumulador[id_cuenta] || 0) + 1;
      return acumulador;
    }, {});


    setTotalInstCPubActivas(Object.keys(publicacionesPorCuenta).length);
  }

  const fetchDataPostulations = async () => {
    setLoadingData(true)
    const data = await getPostulateds(null, 5, true)
    let totalNoEsp = 0
    let totalEsp = 0

    data.map((publication) => {
      totalEsp += publication?.publicacion_id === null
      totalNoEsp += publication?.publicacion_id !== null
    })

    setTotalEspontaneas(totalEsp)
    setTotalNoEspontaneas(totalNoEsp)
    setTotalPostulaciones(data?.length)

    setLoadingData(false)
  }

  const fetchUsers = async () => {
    const data = await getUsers(null, true)

    setCvsConIA(data.filter(user => user?.cuenta?.disponible_ia === -1).length)
    setTotalUsers(data)
    setCvsIncompletos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.fecha_perfil_completo === null).length)
    seTotalPostulantes(data.filter(user => user?.type === 'P').length)
    seTotalPostPublicos(data.filter(user => user?.type === 'P').filter(user => user?.cuenta?.perfil_publico === 1).length)
  }

  useEffect(() => {
    fetchStats()
    fetchDataPostulations()
    fetchGetPublications()
    fetchUsers()
  }, [])

  return (

    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<FaSchool  className="h-6 w-6" />}
          title={"Total instituciones"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.instituciones.toLocaleString('es-AR')}
        />
        <Widget
          icon={<IoMdDocument className="h-6 w-6" />}
          title={"Total publicaciones activas"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.publicaciones.toLocaleString('es-AR')}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"% Instituciones con publicaciones activas"}
          subtitle={isLoading ? <Loader small={true} /> : (totalInstCPubActivas * 100 / stats?.instituciones)?.toFixed(2) + '% ' + '(' + totalInstCPubActivas?.toLocaleString('es-AR') + ')'}
        />

        <Widget
          icon={<IoMdSchool className="h-7 w-7" />}
          title={"Total de postulantes"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.postulantes.toLocaleString('es-AR')}

        />

        <Widget
          icon={<IoDocumentsSharp  className="h-7 w-7" />}
          title={"Postulaciones totales"}
          subtitle={loadingData ? <Loader small={true} /> : totalPostulaciones?.toLocaleString('es-AR')}
          secondTitle={"Espontáneas"}
          secondSubtitle={loadingData ? <Loader small={true} /> : totalEspontaneas?.toLocaleString('es-AR') + ' ' + '(' + (totalEspontaneas * 100 / totalPostulaciones).toFixed(2) + '%' + ')'}
          thirdTitle={"Directas"}
          thirdSubtitle={loadingData ? <Loader small={true} /> : totalNoEspontaneas?.toLocaleString('es-AR') + ' ' + '(' + (totalEspontaneas * 100 / totalNoEspontaneas).toFixed(2) + '%' + ')'}
        />

      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
        <Widget
          icon={<FaUsers className="h-6 w-6" />}
          title={"% Postulantes con perfil abierto"}
          subtitle={loadingData ? <Loader small={true} /> : ((totalPostPublicos / totalPostulantes) * 100)?.toFixed(2) + '% ' + '(' + totalPostPublicos?.toLocaleString('es-AR') + ')'}
        />

        <Widget
          icon={<FaRobot className="h-6 w-6" />}
          title={"Postulantes que cargaron CV con IA"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsConIA}
        />

        <Widget
          icon={<MdDocumentScanner className="h-6 w-6" />}
          title={"Postulantes con perfil incompleto"}
          subtitle={isLoadingUsers ? <Loader small={true} /> : cvsIncompletos + ' ' + '(' + (cvsIncompletos * 100 / totalPostulantes).toFixed(2) + '%' + ')'}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 w-full">
        <TotalSpent users={totalUsers} />
        {/* <WeeklyRevenue /> */}
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5">
        <ComplexTable />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}


        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <MiniCalendar />
        </div>
        <PieChartCard />

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5">

        <CheckTable />
        <ProvincesTable />
      </div>


    </div>
  );
};

export default Dashboard;
